@import url("https://fonts.cdnfonts.com/css/catellos-demo");
@import url("https://fonts.cdnfonts.com/css/inter");

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

/* Logo styling */
.navbar-logo img {
  width: 60px;
}

/* Navigation links styling */
.navbar-links {
  display: flex;
  gap: 5px;
  padding: 10px 20px;
  align-items: center;
}

.navbar-content {
  display: flex;
  gap: 5px; /* Adjust the spacing between icons */
  /* padding: 10px 20px; */
  align-items: center;
}

.navbarx-links:hover .nav-text {
  display: inline; /* Show the text when hovered */
}

.navbarx-links:active .nav-text {
  display: inline; /* Show the text when hovered */
}

.nav-text {
  display: none; /* Hide the text by default */
  margin-left: 8px; /* Adjust the spacing between the icon and text */
}


/* Define your CSS for active NavLink */
.navbar-links .active {
  background-color: #ffffff; /* Background color for the active link */
  border-radius: 30px;
  color: #151515; /* Text color for the active link */
}

/* Define styles for non-active NavLink (optional) */
.navbar-links .navbar-link {
  color: #333;
  visibility: hidden;
}

/* Show text on hover and when active */
.navbar-links .navbar-link:hover,
.navbar-links .active {
  visibility: visible; /* Show the text when hovered or active */
  /* You can add more styles as needed */
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
  transition: background-color 0.3s, border-radius 0.3s;
  fill: #151515;
}

/* Add hover styles for the SVG icons */
.navbar-links a svg {
  fill: #ffffff;
  transition: fill 0.3s;
}

.navbar-links a:hover svg {
  fill: #151515;
}

.navbar-links a:hover {
  background-color: #ffffff;
  border-radius: 30px;
  color: #151515;
}

.navbar-links .active svg {
  background-color: #ffffff; /* Change to your preferred active background color */
  color: #151515; /* Change the text color on active if needed */
  font-weight: 600;
  fill: #151515
}

/* svg {
  width: 20px;
  height: 20px;
  transition: fill 0.2s ease-in-out;
}
svg:hover {
  fill: #151515;
} */

/* User profile styling */
.navbar-user {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 10px;
  transition: background-color 0.3s, border-radius 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}

.navbar-user:hover {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #8ACEF0;
  padding: 10px;
  border-radius: 35px;
  color: #151515;
  /* transform: translateY(-2px); */
  transition: background-color 0.3s, border-radius 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}

.navbar-user img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-avatar {
  width: 50px; /* Adjust the size as needed */
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

/* the end */

/* mobile css */
/* Existing CSS styles */

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
  .navbar {
    /* flex-direction: column; */
    align-items: stretch;
    margin: 10px;
  }

  .navbar-links {
    flex-direction: column;
    padding: 10px 0;
  }

  .navbar-links .navbar-content {
    padding: 10px;
  }

  .nav-text {
    display: inline;
    margin-left: 8px;
  }

  .navbar-links a {
    margin: 5px 0;
  }

  .navbar-user {
    padding: 10px;
  }

  .navbar-user img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
  }
}