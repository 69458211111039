.input .textarea {
  width: 100%;
  /* padding: 10px 20px; */
  /* border-radius: 10px; */
  /* height: 100%; */
  font-weight: 500;
  border: 1px solid #E1E6EF;
  /* background-color: #e4e4e4; */
  /* cursor: progress; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: black;
}

input:focus {
  outline: 1.5px solid #95959b;
}

textarea:focus {
  outline: 1px solid #259BD8;
}