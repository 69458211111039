@import url("https://fonts.cdnfonts.com/css/catellos-demo");
@import url("https://fonts.cdnfonts.com/css/inter");

.user-container {
    display: flex;
    flex-wrap: wrap;
}
  
.user-item {
    width: calc(33.33% - 0px); /* 20px is for margins and paddings */
    /* width: 50%; */
    /* margin-right: 20px; */
    /* gap: 10px; */
    /* margin-bottom: 20px; */
    padding: 10px 0px;
    display: flex;
    /* justify-content: center; */
    text-align: center;
    align-items: center;
    /* background-color: tomato; */
    /* flex-direction: column; */
}
  
.user-image {
    width: 100%;
    max-width: 100%;
    border-radius: 50%;
    height: auto;
}
  
.user-text {
    font-size: 14px;
}

.magazine-item {
    width: calc(50% - 0px); /* 20px is for margins and paddings */
    padding: 10px 0px;
    display: flex;
    text-align: center;
    align-items: center;
    gap: 10px;
}

.magazine-group {
    padding: 10px 0px;
    display: flex;
    text-align: center;
    align-items: center;
}

.magazine-name {
    font-size: 14px;
    font-weight: 600;
}

.magazine-text {
    font-size: 14px;
    margin-left: auto;
}