@import url("https://fonts.cdnfonts.com/css/catellos-demo");
@import url("https://fonts.cdnfonts.com/css/inter");

.container {
  display: flex;
  align-items: center;
  background-color: #EEEEEE;
  background-image: url('../assets/bg-clear.png');
  background-size: cover;
  background-position: center;
  background-repeat:repeat-y;
  height: 100vh;
  justify-content: center;
}