.actions-container {
    display: 'flex';
    align-items: 'center';
    gap: 8
}

.actions-body {
    display: 'flex';
    justify-content: 'center'; 
    align-items: 'center';
    background-color: '#F9F9F9';
    border-radius: '100%';
    padding: 15;
    height: 10;
    width: 10
}

.action-icon {
    display: "flex"
}