body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #259bd8;
}

hr {
  border-color: #f6f6f6;
  border-width: 1px;
}

.foo {
  border: 0.5px solid #e1e6ef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  /* width: 100%; */
  margin: 5px 0;
  padding: 12px;
  border: 1px solid #e1e6ef;
  border-radius: 30px;
}

/* .input:focus {
  outline: 1px solid #259BD8;
} */

textarea {
  width: 100%;
  /* height: auto !important; */
  height: 100%;
  margin: 5px 0;
  padding: 12px;
  border: 1px solid #e1e6ef;
  border-radius: 20px;
}

.icon.active svg path {
  fill: #259bd8; /* Change this to the desired active color */
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
}

.loading_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
