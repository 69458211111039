/* src/components/LoginSimulator.css */
.login-simulator {
    text-align: center;
    margin: auto;
    /* max-width: 280px; */
    width: 270px;
    padding: 20px;
    height: 600px;
    border: 15px solid #151515;
    border-radius: 60px;
    background-color: #ffffff;
    position: relative;
}
  
.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
  

  
/* button {
    margin-top: 10px;
    padding: 10px;
    background-color: #0073e6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */

/* .volume-down {
    display:"flex";
    position: "absolute";
    margin: "auto";
    top: 220;
    left: -340;
    right: 0;
    height: 60;
    width: 10;
    background-color: "#151515";
    border-radius: 30
} */

.version {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 20px;
}
  