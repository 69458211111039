.dropdown {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: -40px;
    padding: 20px 20px 10px 20px;
    border-radius: 21px;
    gap: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: background-color 0.3s, border-radius 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}

.dropdown.show {
    opacity: 1;
    transform: translateY(0);
}
  
.dropdown-list {
    margin-bottom: 10px;
    width: 100%;
    transition: background-color 0.3s, border-radius 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}
  
.dropdown-listt:hover {
    background-color: #f5f5f5;
    border-radius: 10px;
    transition: background-color 0.3s, border-radius 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out;
}  