/* Styles for the uploaded image card */
.image-card {
    border-radius: 20px;
    background-color: #E9E9E9;
    border: 1px solid #dedddd;
    padding: 10px;
    /* position: relative; */
    display: inline-block;
    /* width: 100px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .image-card {
    width: 27.5%; /* Adjust the width to fit three cards per row */
    margin-bottom: 20px;
    /* Additional styles for the card layout */
  }
  
  .upload-image {
    border-radius: 30px;
    border: 1px solid #dedddd;
    background-color: #E9E9E9;
    text-align: center;
    padding: 40px;
  }
  
  .left {
    flex: 0 0 auto; /* Ensures left side doesn't grow */
    margin-right: 10px;
  }
  
  .uploaded-image {
    width: 40px;
    /* height: auto; */
    object-fit: cover;
    margin-right: 10px; /* Add spacing between image and text */
  }
  
  .right {
    flex: 1; /* Takes remaining space */
    display: flex;
    flex-direction: column;
    /* background-color: bisque; */
  }
  
  .top {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 8px;
    text-align: left;
    align-self: flex-start;
  }
  
  .caption {
    text-align: center;
    font-size: 12px;
    color: #B3B8BE;
  }
  
  .bottom {
    display: flex;
    gap: 8px;
    margin-top: auto;
  }