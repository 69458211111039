.button {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  /* height: 40px; */
  height: 100%;
  font-weight: 500;
  border: 1px solid;
  background-color: #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: black;
}

.button:hover {
  opacity: 0.7;
}

/* Style for the dropdown container */
.button-container {
  position: relative;
}

/* Style for the dropdown icon */
.button-container svg {
  font-size: 18px;
  /* margin-left: 5px; */
  vertical-align: middle;
  margin-top: -4px
}

/* Style for the dropdown */
.dropdown {
  position: absolute;
  margin-top: 20px;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  /* border: 1px solid #ccc; */
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 100%;
  padding: 10px;
  color: #333;
}

/* Style for the dropdown items */
.dropdown-item {
  padding: 10px 10px;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  width: 100%;
  border: none;
  color: #333;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  transition: background-color 0.3s ease;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Last dropdown item without border */
.dropdown-item:last-child {
  border-bottom: none;
}