/* modal.css */
.modal-overlay {
  opacity: 1;
  background-color: rgba(51, 51, 51, 0.3);
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: stroke 5.3s ease;
  display: grid;
  place-items: center;
  overflow: hidden;
}
  
.modal {
  width: 600px;
  height: 100%;
  display: flex;
  padding: 0px 15px 15px 15px;
  position: absolute;
  right: 0;
  flex-direction: column;
  background-color: white;
}

.modal-center {
  width: 550px;
  /* height: 100%; */
  display: flex;
  border-radius: 15px;
  padding: 0px 15px 15px 15px;
  position: relative;
  flex-direction: column;
  background-color: white;
}

.modal-close {
  padding: 10px;
  background: #f0f0f0;
  width: 10px;
  height: 10px;
  transition: stroke 0.3s ease;
  display: flex;
  border-radius: 50%;
}

.modal-close:hover {
    padding: 10px;
    background: #a5a5a5;
    fill: #ffffff;
    stroke: #FF0000;
    transition: stroke 0.3s ease;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}

/* YourComponent.css or your global styles */
.modal-close svg {
    transition: stroke 0.3s ease;
    stroke: #28303F;
}

.modal-close:hover svg {
    fill: #ffffff;
    stroke: #FF0000;
    color: red;
}

.modal-header {
    padding: 0px 0px 30px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E1E6EF;
    justify-content: center;
    margin-bottom: 15px;
    /* background-image: url('../assets/modal-header-bg.png'); */
    background-size: cover;
    background-position: center;
}

.modal-body {
    padding: 5px 5px 10px 5px;
    /* max-height: 400px; */
    overflow-y: auto;
    /* background-color: #d20000; */
}

/* Style the scrollbar track and thumb */
.modal-body::-webkit-scrollbar {
  width: 2px; /* Set your preferred width */
  /* padding: 5px 5px; */
}

.modal-body::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #151515;
  /* opacity: 0.3; */
  /* border-radius: 10px; */
}

.modal-footer {
    border-top: 1px solid #E1E6EF;
    padding: 15px 0px 0px 0px;
    display: flex;
    margin-top: auto;
    gap: 10px;
}

.custom-input {
    text-align: right;
    color: #538bff;
    height: 46px !important;
    line-height: 46px !important;
    &::placeholder {
      color: #538bff;
    }
  }
  .custom-days {
    background-color: #c8daff;
    .is-week-days {
      color: #d20000;
    }
    .is-disabled {
      color: #444;
    }
  }
  .custom-header {
    background-color: #538bff;
  }
  

/* multi image upload styles - temporary usage */
.multiimageupload {
  border: 1px solid rgb(225, 230, 239);
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
}

/* single image upload */
/* Styles for the placeholder (plus icon) */
.image-placeholder {
  border: 1px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.placeholder-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}