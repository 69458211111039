.responsive-table-container {
    overflow-x: auto;
    padding: 25px 0px;
  }
  
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 1em; */
  /* margin-bottom: 1em; */
  overflow: hidden;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
}
  
.responsive-table th {
  font-weight: 500;
  /* font-size: 18px; */
  padding: 22px 12px;
  text-align: left;
  border-bottom: 0.5px solid #E1E6EF;;
}

.responsive-table td {
  padding: 5px 15px;
  text-align: left;
  font-size: 14px;
  border-bottom: 0.5px solid #E1E6EF;
  justify-content: center;
  align-items: center;
  align-self: center;
}
  
  /* .responsive-table th {
    background-color: #f8f8f8;
    min-width: 150px;
  } */
  
.responsive-table td:before {
  content: attr(data-label);
  float: left;
  /* font-weight: bold; */
  text-transform: uppercase;
}

.checkbox-table-container {
  overflow-x: auto;
  padding: 25px 0px;
}

.checkbox-table {
width: 100%;
border-collapse: collapse;
overflow: hidden;
}

.checkbox-table th {
font-weight: 500;
/* font-size: 18px; */
/* padding: 22px 12px; */
text-align: center;
border-bottom: 0.5px solid #E1E6EF;;
}

.checkbox-table td {
padding: 5px 15px;
/* text-align: center; */
font-size: 12px;
border-bottom: 0.5px solid #E1E6EF;
justify-content: center;
align-items: center;
align-self: center;
}

.checkbox-table input {
  padding: 5px 15px;
  text-align: center;
  font-size: 12px;
  border-bottom: 0.5px solid #E1E6EF;
  justify-content: center;
  align-items: center;
  align-self: center;
  }

.checkbox-table td:before {
content: attr(data-label);
float: left;
text-transform: uppercase;
}

.checkbox-input {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
}

/* Style for the checkbox input */
.checkbox-input input[type='checkbox'] {
  -webkit-appearance: none; /* Remove default styles on Safari/Chrome */
  -moz-appearance: none; /* Remove default styles on Firefox */
  appearance: none; /* Remove default styles on other browsers */
  width: 0.4375em !important;
  height: 0.4375em;
  font-size: 10px;
  border: 1px solid #aaa; /* Add a border */
  border-radius: 50px; /* Add some border radius */
  outline: none; /* Remove the default focus outline */
  cursor: pointer; /* Change cursor on hover */
}

/* Style for checked state */
.checkbox-input input[type='checkbox']:checked {
  background-color: #00C98D; /* Change the background color when checked */
  border-color: #00C98D; /* Change the border color when checked */
}